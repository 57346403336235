<template>
  <div :id="divId" :data="dataString" aria-hidden="true"><slot></slot></div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';

export default defineComponent({
  props: {
    id: {type: String, default: undefined},
    data: {type: Object, default: undefined}
  },
  setup(props) {
    const divId = computed(() => {
      if (props.id === undefined) {
        return undefined;
      }
      return `test-anchor-${props.id}`;
    });
    const dataString = computed(() => {
      if (props.data !== undefined) {
        return JSON.stringify(props.data);
      }
      return '';
    });
    return {
      divId,
      dataString
    };
  }
});
</script>
