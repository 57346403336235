/**
 * Polyfills. From Babel 7.4.0 onwards, @babel/polyfill is deprecated.
 * Instead, it is recommended to include the core-js npm package in the
 * project and import it here. The regenerator-runtime import is needed to
 * allow async mounted() functions in Vue components. (It is part of the
 * core-js npm package.)
 *
 * For details, see
 *
 * https://babeljs.io/docs/en/babel-polyfill
 * https://github.com/zloirock/core-js#usage
 * https://alexbogovich.com/blog/core-js/
 */

/** CL: ### `import 'regenerator-runtime/runtime';` is required for core-js to properly work with babel. When setting `useBuiltIns: 'usage'`, polyfills are automatically imported when needed.
 * When `useBuiltIns:` is set to `usage`, direct import of `core-js` is unneccessary. If `import 'core-js';` is included in this file use `useBuiltIns: 'entry'` instead.
 *
 * Intelligent loading of polyfills depends on the targets set in `"browserslist":` config in package.json.
 *
 * NOTE: `npx browserslist@latest --update-db` updates caniuse-lite version in your npm, yarn or pnpm lock file. See the following for details: https:*github.com/browserslist/browserslist
 * FYI: Run 'npx browserslist' in your project directory to see project’s target browsers. This CLI tool is built-in and available in any project with Autoprefixer.
 */
// import 'core-js/stable';
// import regeneratorRuntime from "regenerator-runtime";

import './css/global.css';
import {defineAsyncComponent} from 'vue';
import {createVueApp} from './utils/vue';

/**
 * Static Components
 *
 * These components are always included during the initial load (defined in index.js).
 * They are critical for the core functionality of the site and are loaded synchronously.
 */

import ErrorReporter from './components/core/error-reporter/ErrorReporter.vue';
import FullScreenLoader from './components/generic/loader/FullScreenLoader.vue';
import HelpScout from './components/core/site-wrapper/help-scout/HelpScout.vue';
import Instrumentation from './components/core/site-wrapper/instrumentation/Instrumentation.vue';
import NotificationPopup from './components/generic/notification-popup/NotificationPopup.vue';
import Sidebar from './components/core/site-wrapper/sidebar/Sidebar.vue';
import SiteFooter from './components/site-footer/SiteFooter.vue';
import SiteHeader from './components/site-header/SiteHeader.vue';
import SiteWrapper from './components/core/site-wrapper/SiteWrapper.vue';

/**
 * Dynamic Components
 *
 * These components are lazy-loaded when referenced in a Craft Twig template.
 * Lazy loading helps optimize performance by only fetching the components when they are required.
 *
 * These components can be imported into Twig templates using the Twigpack Craft Plugin,
 * which translates the import into a corresponding script tag. This script tag ensures
 * that the component is loaded dynamically at the appropriate time.
 *
 * Example usage in a Twig template:
 * {{ craft.twigpack.includeJsModule("login.js") }}
 */
const About = defineAsyncComponent(
  () => import(/* webpackChunkName: "about" */ './components/content-pages/about/About.vue')
);
const Account = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "account" */ './components/user-pages/account/pages/account/Account.vue'
    )
);
const AccountAddOn = defineAsyncComponent(
  () => import(/* webpackChunkName: "account-addon" */ './components/offer/add-on/AccountAddOn.vue')
);
const AllBookmarks = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-bookmarks" */ './components/user-pages/bookmarks/AllBookmarks.vue'
    )
);
const AllCareers = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-careers" */ './components/content-pages/careers/AllCareers.vue'
    )
);
const AllCourses = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-courses" */ './components/content-pages/courses/AllCourses.vue'
    )
);
const AllCuration = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-curation" */ './components/content-pages/curation/AllCuration.vue'
    )
);
const AllInterviews = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-interviews" */ './components/content-pages/interviews/AllInterviews.vue'
    )
);
const AllLearningPathways = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-learning-pathways" */ './components/content-pages/learning-pathways/AllLearningPathways.vue'
    )
);
const AllMemberDiscounts = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-member-discounts" */ './components/content-pages/member-discounts/AllMemberDiscounts.vue'
    )
);
const AllMentorSeminars = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-mentor-seminars" */ './components/content-pages/mentor-seminars/AllMentorSeminars.vue'
    )
);
const AllPodcasts = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-podcasts" */ './components/content-pages/podcasts/AllPodcasts.vue'
    )
);
const AllBlogPosts = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "all-blog-posts" */ './components/content-pages/blog/AllBlogPosts.vue'
    )
);
const AllShows = defineAsyncComponent(
  () => import(/* webpackChunkName: "all-shows" */ './components/content-pages/shows/AllShows.vue')
);
const AllTutors = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "all-tutors" */ './components/content-pages/tutors/AllTutors.vue')
);
const BeginnerJourney = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "beginner-journey" */ './components/content-pages/beginner-journey/BeginnerJourney.vue'
    )
);
const BrowserCompatibility = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "browser-compatibility" */ './components/user-pages/terms-of-service/BrowserCompatibility.vue'
    )
);
const Cancel = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "cancel" */ './components/user-pages/account/pages/cancel/Cancel.vue'
    )
);
const Career = defineAsyncComponent(
  () => import(/* webpackChunkName: "career" */ './components/content-pages/careers/Career.vue')
);
const Contact = defineAsyncComponent(
  () => import(/* webpackChunkName: "contact" */ './components/user-pages/contact/Contact.vue')
);
const Course = defineAsyncComponent(
  () => import(/* webpackChunkName: "course" */ './components/content-pages/courses/Course.vue')
);
const Curation = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "curation" */ './components/content-pages/curation/Curation.vue')
);
const Dashboard = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "dashboard" */ './components/user-pages/dashboard/Dashboard.vue')
);
const Delete = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "delete" */ './components/user-pages/account/pages/delete/Delete.vue'
    )
);
const Email = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "email" */ './components/user-pages/profile/pages/email/Email.vue')
);
const EmailPreferences = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "email-preferences" */ './components/user-pages/email-preferences/EmailPreferences.vue'
    )
);
const GlobalSearch = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "global-search" */ './components/global-search/GlobalSearch.vue')
);
const GrooveTrainer = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "groove-trainer" */ './components/content-pages/groove-trainer/GrooveTrainer.vue'
    )
);
const History = defineAsyncComponent(
  () => import(/* webpackChunkName: "history" */ './components/user-pages/history/History.vue')
);
const Interview = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "interview" */ './components/content-pages/interviews/Interview.vue'
    )
);
const LearningPathway = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "learning-pathway" */ './components/content-pages/learning-pathways/LearningPathway.vue'
    )
);
const Lesson = defineAsyncComponent(
  () => import(/* webpackChunkName: "lesson" */ './components/content-pages/lessons/Lesson.vue')
);
const LiveClass = defineAsyncComponent(
  () => import(/* webpackChunkName: "live-class" */ './components/live-class/LiveClass.vue')
);
const Login = defineAsyncComponent(
  () => import(/* webpackChunkName: "login" */ './components/user-pages/login/Login.vue')
);
const MentorSeminar = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "mentor-seminar" */ './components/content-pages/mentor-seminars/MentorSeminar.vue'
    )
);
const MentorSeminarsMain = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "mentor-seminars-main" */ './components/content-pages/mentor-seminars/MentorSeminarsMain.vue'
    )
);
const NotFound = defineAsyncComponent(
  () => import(/* webpackChunkName: "not-found" */ './components/not-found/NotFound.vue')
);
const Orientation = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "orientation" */ './components/content-pages/orientation/Orientation.vue'
    )
);
const Password = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "password" */ './components/user-pages/profile/pages/password/Password.vue'
    )
);
const PlayersPathLevel = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "players-path-level" */ './components/content-pages/players-path/PlayersPathLevel.vue'
    )
);
const PlayersPathMain = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "players-path-main" */ './components/content-pages/players-path/PlayersPathMain.vue'
    )
);
const PlayersPathPiece = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "players-path-piece" */ './components/content-pages/players-path/PlayersPathPiece.vue'
    )
);
const Podcast = defineAsyncComponent(
  () => import(/* webpackChunkName: "podcast" */ './components/content-pages/podcasts/Podcast.vue')
);
const BlogPost = defineAsyncComponent(
  () => import(/* webpackChunkName: "blog-post" */ './components/content-pages/blog/BlogPost.vue')
);
const PrivacyPolicy = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "privacy-policy" */ './components/user-pages/privacy-policy/PrivacyPolicy.vue'
    )
);
const Profile = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "profile" */ './components/user-pages/profile/pages/profile/Profile.vue'
    )
);
const Quiz = defineAsyncComponent(
  () => import(/* webpackChunkName: "quiz" */ './components/content-pages/quiz/Quiz.vue')
);
const ResetPassword = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "reset-password" */ './components/user-pages/reset-password/ResetPassword.vue'
    )
);
const Resubscribe = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "resubscribe" */ './components/user-pages/account/pages/resubscribe/Resubscribe.vue'
    )
);
const SetPassword = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "set-password" */ './components/user-pages/set-password/SetPassword.vue'
    )
);
const Show = defineAsyncComponent(
  () => import(/* webpackChunkName: "show" */ './components/content-pages/shows/Show.vue')
);
const Signup = defineAsyncComponent(
  () => import(/* webpackChunkName: "signup" */ './components/signup/Signup.vue')
);
const SignupAddOn = defineAsyncComponent(
  () => import(/* webpackChunkName: "signup-add-on" */ './components/offer/add-on/SignupAddOn.vue')
);
const SignupUpgrade = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "signup-upgrade" */ './components/offer/add-on/SignupUpgrade.vue')
);
const Survey = defineAsyncComponent(
  () => import(/* webpackChunkName: "survey" */ './components/user-pages/survey/Survey.vue')
);
const TermsOfService = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "terms-of-service" */ './components/user-pages/terms-of-service/TermsOfService.vue'
    )
);
const ThankYouPage = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "thank-you-page" */ './components/offer/thank-you-page/ThankYouPage.vue'
    )
);
const Toolkit = defineAsyncComponent(
  () => import(/* webpackChunkName: "toolkit" */ './components/toolkit/Toolkit.vue')
);
const ToolkitLesson = defineAsyncComponent(
  () => import(/* webpackChunkName: "toolkit-lesson" */ './components/toolkit/ToolkitLesson.vue')
);
const Tutor = defineAsyncComponent(
  () => import(/* webpackChunkName: "tutor" */ './components/content-pages/tutors/Tutor.vue')
);
const UpcomingEntry = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "upcoming-entry" */ './components/content-pages/upcoming-entry/UpcomingEntry.vue'
    )
);
const Update = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "update" */ './components/user-pages/account/pages/update/Update.vue'
    )
);
const Upgrade = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "upgrade" */ './components/user-pages/account/pages/upgrade/Upgrade.vue'
    )
);
const Username = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "username" */ './components/user-pages/profile/pages/username/Username.vue'
    )
);

/*
  Let TypeScript know of the HelpScout beacon type.
*/
declare global {
  // ### Get rid of this when/if the HelpScout API supports TypeScript.
  const Beacon: any;

  /*
    ### reCAPTCHA defines a global 'grecaptcha' object when it's loaded; 
    Unfortunately it doesn't look like there is an official TS type package.
    When/if Google decides to release one, we should use that instead.
    
    For info on what these objects do, see
    https://developers.google.com/recaptcha/docs/loading
  */
  interface Window {
    grecaptcha: any | undefined;
    ___grecaptcha_cfg: any | undefined;
  }
}

const app = createVueApp({
  /**
   * These are the Vue components that should be possible to
   * access directly in Craft templates. There is no need to list
   * sub-components (i.e., components that are used by the components
   * in this list but not directly in templates).
   */
  components: {
    About,
    Account,
    AccountAddOn,
    AllBlogPosts,
    AllBookmarks,
    AllCareers,
    AllCourses,
    AllCuration,
    AllInterviews,
    AllLearningPathways,
    AllMemberDiscounts,
    AllMentorSeminars,
    AllPodcasts,
    AllShows,
    AllTutors,
    BeginnerJourney,
    BlogPost,
    BrowserCompatibility,
    Cancel,
    Career,
    Contact,
    Course,
    Curation,
    Dashboard,
    Delete,
    Email,
    EmailPreferences,
    ErrorReporter,
    FullScreenLoader,
    GlobalSearch,
    GrooveTrainer,
    HelpScout,
    History,
    Instrumentation,
    Interview,
    LearningPathway,
    Lesson,
    LiveClass,
    Login,
    MentorSeminar,
    MentorSeminarsMain,
    NotFound,
    NotificationPopup,
    Orientation,
    Password,
    PlayersPathLevel,
    PlayersPathMain,
    PlayersPathPiece,
    Podcast,
    PrivacyPolicy,
    Profile,
    Quiz,
    ResetPassword,
    Resubscribe,
    SetPassword,
    Show,
    Sidebar,
    Signup,
    SignupAddOn,
    SignupUpgrade,
    SiteFooter,
    SiteHeader,
    SiteWrapper,
    Survey,
    TermsOfService,
    ThankYouPage,
    Toolkit,
    ToolkitLesson,
    Tutor,
    UpcomingEntry,
    Update,
    Upgrade,
    Username
  }
});

app.mount('#app');
